import { template as template_480dfbd551e64ca68fe2a34151f6503b } from "@ember/template-compiler";
const FKText = template_480dfbd551e64ca68fe2a34151f6503b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
