import { template as template_aa34af3a94b94f22aa0f247e9a4d3ef1 } from "@ember/template-compiler";
const FKLabel = template_aa34af3a94b94f22aa0f247e9a4d3ef1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
