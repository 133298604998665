import { template as template_1aaa9564eb914b9a99e382f2849bb313 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
import icon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
const BulkSelectCell = template_1aaa9564eb914b9a99e382f2849bb313(`
  <th class="bulk-select topic-list-data">
    {{#if @canBulkSelect}}
      <button
        {{on "click" @bulkSelectHelper.toggleBulkSelect}}
        title={{i18n "topics.bulk.toggle"}}
        class="btn-flat bulk-select"
      >
        {{icon "list-check"}}
      </button>
    {{/if}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
